html, body {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
} 

#root {
	width: 100%;
	height: 100%;
}

#code {
	width: 400px;
}

#editor {
	resize: none;
	padding: 3px;
}

#editor:focus {
	outline: none;
}

#handle {
	width: 100%;
	height: 5px;
}

#preview {
	margin-left: 405px;
}

@media screen and (min-width: 768px) {
	#handle {
		width: 5px;
		height: 100%;
		cursor: ew-resize;
	}
}
